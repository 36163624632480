<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 col-12">
        <div class="card card-top card-top-primary">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Online Training Yönetim Paneline Hoşgeldiniz' }" />
            <div class='card-body'>
              <img src='/logo.svg' alt='Logo'>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Anasayfa',
}
</script>
